<template>
  <div class="relative z-50 mx-auto my-60">
    <i class="fas fa-spinner animate-spin text-6xl text-csgreen"></i>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
}
</script>

<style></style>
